<template>
  <article class="disclosure" :class="{ 'l-webscreens': $route.name.includes('webscreens') }">
    <h1>PRIVACY</h1>
    <p class="primary-color"><strong>Effective Date: July 1, 2023</strong></p>
    <p class="primary-color"><strong>Date Last Updated: July 1, 2023</strong></p>
    <ol>
      <li>
        <strong class="underline">Introduction</strong>
        <p>
          Electrify America LLC, its parent and/or subsidiaries (“Electrify America,” “us,” “we,” or “our”), collects
          and processes information about you as described in this Privacy Notice (“Notice”). We are committed to
          protecting the privacy of those with whom we interact. This Notice contains details about how we collect, use,
          and share Personal Information that we obtain from and about you when you interact with us via our website,
          email, mobile application, social media accounts (collectively, our “Digital Properties”), charging stations,
          or Call Center, and in other online and offline interactions. Please read this Notice carefully.
        </p>
        <p>
          <strong>Applicability:</strong>
          We collect Personal Information in several contexts as described below. However, this Notice does not apply to
          the following information:
        </p>
        <ul>
          <li>
            Information about our employees, contractors, agents, and job applicants. Such information is subject to a
            separate privacy notice that we will make available to individuals.
          </li>
          <li>
            Information we collect when providing services to one of our business customers, such as when we provide
            services to our automotive manufacturer customers. In these cases, our business customers’ privacy notices
            will apply and you should contact them for any questions about their privacy practices.
          </li>
        </ul>
        <p>
          <strong>Changes:</strong>
          We may update this Notice from time to time. The current Notice will be effective when posted. Please check
          this Notice periodically for updates. If any of the changes are unacceptable to you, you should cease
          interacting with us. When required under applicable law, we will notify you of any changes to this Notice by
          posting an update on this website.
        </p>
      </li>
      <li>
        <strong class="underline">Sources of Personal Information</strong>
        <p>We collect information about you and how you interact with us in several ways, including:</p>
        <ul class="lower-latin">
          <li>
            <strong>Information you provide to us directly.</strong> We collect the information you provide to us
            directly, including to service providers who collect the Personal Information on our behalf, such as when
            you contact us through our Digital Properties, interact with us in person, sign up for offers or
            newsletters, place orders, sign up for an account or other services.
          </li>
          <li>
            <strong>Information automatically collected or inferred from your online interaction with us.</strong> We
            may automatically collect information or inferences about you, such as through cookies and other tracking
            technologies, when you interact with our Digital Properties. This may include information about how you use
            and interact with our Digital Properties, information about your device, and internet usage information.
          </li>
          <li>
            <strong>Information from public sources.</strong> We collect information from public sources, including
            government entities from which public records are obtained and information you submit in public forums and
            public social media information.
          </li>
          <li>
            <strong>Information from third parties.</strong> We receive information about you and your interactions with
            us from third parties, such as service providers, entities who sell and/or administer our products, charging
            station providers, partners who host our charging stations at their location(s), vehicle manufacturers and
            dealers, utility providers, marketing and advertising providers, social network services, ecommerce
            platforms, and data brokers.
          </li>
        </ul>
        <p>
          We may combine information that we receive from the various sources described in this Notice, including third
          party sources and public sources, and use or disclose it for the purposes identified below. In addition, if
          you have multiple Electrify America accounts, any profile changes to one Electrify America account may also be
          reflected across all other Electrify America accounts.
        </p>
      </li>
      <li>
        <strong class="underline">Types of Personal Information We Collect</strong>
        <p>The types of information that we collect about you includes:</p>
        <ul class="lower-latin">
          <li>
            <strong>Identifiers</strong>, such as your name, alias, postal address, unique personal identifier, online
            identifier, charging station user ID, session ID, internet protocol address, email address, account name,
            telephone number, social media identifiers (e.g., Twitter handle, Instagram name, etc.), driver’s license
            number, credit card number, or other similar identifiers.
          </li>
          <li><strong>Customer records</strong>, such as signatures.</li>
          <li><strong>Protected Class and Demographic information</strong>, such as age and gender.</li>
          <li>
            <strong>Commercial information and preferences</strong>, including records of personal property (such as VIN
            and vehicle information); products or services purchased, obtained, or considered, or other purchasing or
            consuming histories or tendencies; the content, timing and method of communications you have with us, such
            as online chats, calls, and emails; payment and delivery details or other financial information; and legal
            information relating to your transactions, such as fraud checks or flags raised about your transactions,
            payment card refusals, complaints and information related to their resolution.
          </li>
          <li>
            <strong>Internet or other electronic network activity information</strong>, such as your browsing history,
            search history, and information regarding your interactions with us (including interacting with us online,
            by the mobile application, and through advertisements).
          </li>
          <li>
            <strong>Geolocation information</strong>, such as an approximate location based on your IP address, and the
            addresses of charging stations you’ve used to charge your vehicle.
          </li>
          <li>
            <strong>Audio, electronic, visual, thermal, olfactory or similar information</strong>, including video and
            audio recordings of our premises and charging stations (such as security footage or voice commands) and call
            center recordings.
          </li>
          <li>
            <strong>Professional or employment information</strong>, such as job title, employer, business address and
            contact information, employment history, other professional information, or education history.
          </li>
          <li><strong>Education Information</strong>, such as education history.</li>
          <li>
            <strong>Inferences</strong> drawn from any of the information we collect to assess the level of interest in
            our products and services based on frequency of visits and contact and determine your preferred frequency
            for receiving offers.
          </li>
          <li>
            <strong>Sensitive Personal Data</strong>, including the following:
            <ul class="lower-latin margin-top">
              <li>Account log-in information.</li>
              <li>Precise geolocation.</li>
              <li>Content of mail, email, and text messages where we are not the intended recipient.</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <strong class="underline">How We Use Your Personal Information</strong>
        <p>We may use each category of your Personal Information described above in the following ways:</p>
        <ul class="lower-latin">
          <li>
            <strong>To enable interactions between you and us</strong>, such as to facilitate online or in-person
            purchases or uses of our products; evaluate proposals submitted to our website; process shipping and
            returns; register and administer your account; provide you with and support your interactions with us;
            diagnose, repair and track service and quality issues; facilitate an order, installation, download, return,
            or exchange; process a warranty claim; provide requested product information; host informational webinars;
            communicate with you about your account or our data practices; install and configure changes and updates to
            programs and technologies related to interactions with us; authenticate those who interact with us; or to
            respond to your requests, complaints, and inquiries.
          </li>
          <li>
            <strong>For our own internal business purposes</strong>, such as to evaluate or audit the usage and
            performance of programs and technologies related to interactions with us; evaluate and improve the quality
            of your interactions with us and programs and technologies related to interactions with us; design new
            services; process and catalog your responses to surveys or questionnaires (e.g., customer satisfaction
            reviews); perform internal research for technological development and demonstration; conduct data analysis
            and testing; maintain proper business records and other relevant records.
          </li>
          <li>
            <strong>For legal, safety, or security reasons</strong>, such as to comply with legal requirements; comply
            with reporting and similar requirements; investigating and responding to claims against the Company and its
            customers; completing due diligence (such as in connection with a corporate transaction); protect our
            safety, our property or rights of those who interact with us, or others; and detect, prevent, and respond to
            security incidents or other malicious, deceptive, fraudulent, or illegal activity.
          </li>
          <li>
            <strong>In a de-identified, anonymized, or aggregated format</strong>. We may convert Personal Information
            into a de-identified, anonymized, or aggregated format, and use such information for any legal purpose.
          </li>
          <li>
            <strong>For marketing and targeted advertising</strong>. We may use your Personal Information to market our
            products or services or those of third parties, such as our affiliates and business partners. When we say
            “we’”/”our” in this section, this includes our affiliates, business partners, or other third parties we may
            be working with. For example, we may use Personal Information we collect to personalize advertising to you
            on this website or others, to analyze interactions with our email communications or website areas viewed, to
            develop product, brand or services audiences (including by identifying you across devices/sites) to better
            target our advertising to you, or to send you newsletters, surveys, questionnaires, promotions, or
            information about events or webinars. For more information about online marketing activity, see Section 6
            below.
          </li>
          <li><strong>For any other purposes for which you provide consent</strong>.</li>
        </ul>
      </li>
      <li>
        <strong class="underline">With Whom We Share Your Personal Information</strong>
        <p>We may share your Personal Information with the categories of recipients described below:</p>
        <ul class="lower-latin">
          <li>
            <strong>Affiliates and subsidiaries</strong>: We may share your Personal Information with affiliated
            companies, including parents, subsidiaries, business units and other companies that share common ownership
            for the purposes described above.
          </li>
          <li>
            <strong>Service providers</strong>: We may share your Personal Information with service providers working on
            our behalf in order to facilitate our interactions with you or request or support our relationship with you,
            such as cloud service providers, IT providers, operating systems and ecommerce platforms, internet service
            providers, analytics companies, and marketing providers (e.g., we may share your email address with our
            outbound email marketing provider). We may contract with other companies to provide certain services,
            including identity verification, email distribution, market research, promotions management and payment
            processing.
          </li>
          <li>
            <strong>Third parties necessary to complete a transaction or request</strong>. We may disclose information
            to third parties to provide you with services or benefits you may request, such shipping and delivery
            service providers. We may also share information with third parties at your direction. For example, if you
            choose to integrate our Home product with a virtual assistant, your personal information may be shared with
            that third party provider to perform the services. You should consult those third-party privacy notices for
            information on their privacy practices.
          </li>
          <li>
            <strong>Business partners</strong>: We may also provide your Personal Information or provide access to your
            Personal Information to our business partners, including for third party marketing, such as:
            <ul class="margin-top">
              <li>
                Marketing and research partners, including companies that operate cookies and other tracking
                technologies, social media companies, and other business partners for their own marketing, research or
                analytics purposes;
              </li>
              <li>
                Vehicle manufacturers and dealers who arrange for or pay for some or all of the services you receive;
              </li>
              <li>Charging station site hosts;</li>
              <li>Utility companies;</li>
              <li>Charging network operators who we collaborate with for interoperability; and</li>
              <li>
                Commercial and/or fleet customers who may arrange for us to provide our services to their drivers.
              </li>
            </ul>
          </li>
          <li>
            <strong>For legal, security and safety purposes</strong>: We may share your Personal Information with third
            parties such as law enforcement or other government agencies to evaluate proposals, comply with laws or
            legal requirements; to enforce or apply our Terms of Use and other agreements; and to protect our rights and
            our property or safety of our users or third parties.
          </li>
          <li>
            <strong>In connection with a corporate transaction</strong>: We may transfer any information we have about
            you in connection with corporate transactions, such as a change in corporate control (including but not
            limited to a merger, sale, reorganization, or transaction related to insolvency or bankruptcy proceedings)
            involving all or part of Electrify America’s assets.
          </li>
        </ul>
        <p>
          We may also de-identity, anonymize, or aggregate Personal Information to share with third parties for any
          purpose.
        </p>
      </li>
      <li>
        <strong class="underline">How We Use Cookies and Automatic Data Collection Tools</strong>
        <p>
          Our Digital Properties (and authorized third parties) use cookies and other tracking technologies to collect
          information about you, your device, and how you interact with our Digital Properties. This section contains
          additional information about:
        </p>
        <ul>
          <li>The types of tracking technologies we use and the purposes for which we use them</li>
          <li>The types of information we collect using these technologies</li>
          <li>How we disclose or make information available to others</li>
          <li>Choices you may have regarding these technologies</li>
        </ul>
        <ul class="lower-latin">
          <li>
            <strong><em>Types of cookies and tracking technologies we use</em></strong>
            <p>
              Our Digital Properties and the third parties that we authorize may use the following tracking
              technologies:
            </p>
            <ul>
              <li>
                Cookies, which are a type of technology that install a small amount of information on a user's computer
                or other device when they visit a website. Some cookies exist only during a single session and some are
                persistent over multiple sessions over time.
              </li>
              <li>
                Pixels, web beacons, and tags, which are types of code or transparent graphics that contain a unique
                identifier. In addition to the uses described in Section 5.2 below, these technologies provide
                analytical information about the user experience and help us customize our marketing activities. In
                contrast to cookies, which are stored on a user's computer hard drive, pixels, web beacons, and tags are
                embedded invisibly on web pages.
              </li>
              <li>
                Session replay tools, which record your interactions with our Digital Properties, such as how you move
                throughout our Digital Properties and engage with our webforms. In addition to the uses described in
                Section 5.2 below, this information helps us improve our Digital Properties and identify and fix
                technical issues visitors may be having with our Digital Properties.
              </li>
            </ul>
          </li>
          <li>
            <strong><em>Purposes for using these technologies</em></strong>
            <p>
              We and authorized third parties use these technologies to remember user preferences, login details and
              browsing behavior; improve the performance of our Digital Properties, products, and services; conduct
              analytics; provide you with offers that may be of interest to you; track your activity across online
              properties over time to better understand your preferences and interests; to conduct advertising on our
              Digital Properties and those of third parties; measure the effectiveness of our communications with you
              and advertising campaigns, including identifying how and when you engage with one of our emails; and
              personalize online content. For example, we use Google Analytics to help us improve our Digital
              Properties, performance and user experiences. Google Analytics may use cookies and other tracking
              technologies to perform their services. To learn how Google Analytics collects and processes data, please
              visit: “How Google uses data when you use our partners’ sites or apps” located at
              <a class="link" rel="noopener" target="_blank" href="https://www.google.com/policies/privacy/partners"
                >www.google.com/policies/privacy/partners</a
              >.
            </p>
          </li>
          <li>
            <strong><em>Information collected</em></strong>
            <p>
              These tracking technologies collect data about you and your device, such as your IP address, cookie ID,
              device ID, AdID, operating system, browser used, browser history, search history, and information about
              how you interact with our Digital Properties (such as pages on our Digital Properties that you have
              viewed).
            </p>
          </li>
          <li>
            <strong><em>Disclosures of your information</em></strong>
            <p>
              We may disclose information to third parties or allow third parties to directly collect information using
              these technologies on our Digital Properties, such as social media companies, advertising networks,
              companies that provide analytics including ad tracking and reporting, security providers, and others that
              help us operate our business and Digital Properties.
            </p>
          </li>
          <li>
            <strong><em>Your choices</em></strong>
            <p>
              Some of the third parties we work with participate with the Digital Advertising Alliance ("DAA") and
              Network Advertising Initiative ("NAI"). The DAA and NAI provide mechanisms for you to opt out of
              interest-based advertising performed by participating members at
              <a class="link" rel="noopener" target="_blank" href="http://www.aboutads.info/choices/"
                >http://www.aboutads.info/choices/</a
              >
              and
              <a rel="noopener" target="_blank" class="link" href="https://optout.networkadvertising.org/"
                >https://optout.networkadvertising.org/</a
              >. We adhere to the DAA’s Self-Regulatory Principles for Online Behavioral Advertising. You may also click
              on the AboutAds icon on an Electrify America advertisement and follow the instructions on how to opt out.
            </p>
            <p>
              You can also refuse or delete cookies using your browser settings. If you refuse or delete cookies, some
              of our Digital Properties’ functionality may be impaired. Please refer to your browser’s Help instructions
              to learn more about how to manage cookies and the use of other tracking technologies. If you change
              computers, devices, or browsers; use multiple computers, devices, or browsers; or delete your cookies, you
              may need to repeat this process for each computer, device, or browser. Opting out of interest-based
              advertising will not opt you out of all advertising, but rather only interest-based advertising from
              Electrify America or its agents or representatives.
            </p>
            <p>
              Some browsers have incorporated Do Not Track (“DNT”) preferences. If you set your browser to signal that
              you do not wish to be tracked online, we will honor your preference by disabling social media and targeted
              advertising cookies.
            </p>
          </li>
        </ul>
      </li>
      <li>
        <strong class="underline">Security and Retention</strong>
        <p>
          We maintain reasonable security procedures and technical and organizational measures to protect your Personal
          Information against accidental or unlawful destruction, loss, disclosure, alteration, or use.
        </p>
      </li>
      <li>
        <strong class="underline">Children’s Privacy</strong>
        <p>
          Interactions with us are intended for individuals 16 years of age and older. Our interactions are not directed
          at, marketed to, nor intended for, children under 16 years of age. We do not knowingly collect any
          information, including Personal Information, from children under 16 years of age. If you believe that we have
          inadvertently collected Personal Information from a child under the age of 16, please contact us at the
          address below and we will use reasonable efforts to delete the child’s information from our databases.
        </p>
      </li>
      <li>
        <strong class="underline">External Links</strong>
        <p>
          When interacting with us you may encounter links to external sites or other online services, including those
          embedded in third party advertisements or sponsor information that we do not control. We are not responsible
          for the privacy practices and data collection policies for such third party services. You should consult the
          privacy statements of those third party services for details.
        </p>
      </li>
      <li>
        <strong class="underline">Terms of Use</strong>
        <p>
          The Terms of Use for interactions with us is incorporated by reference into this Notice, and can be found at:
          <router-link class="link" :to="{ name: 'terms-en' }">https://www.electrifyamerica.com/terms/.</router-link>
        </p>
      </li>
      <li>
        <strong class="underline">Contact Info/Your Choices</strong>
        <p>
          If you have questions regarding this Notice, please contact us at
          <a class="link" href="mailto:privacy@electrifyamerica.com">privacy@electrifyamerica.com.</a>
        </p>
        <p>
          Nevada residents: please contact us at
          <a class="link" href="mailto:privacy@electrifyamerica.com">privacy@electrifyamerica.com.</a> to inquire about
          your right to opt out of the sale of your personal information.
        </p>
        <p>
          <strong><em>California Shine the Light:</em></strong> If you are a California resident, you may opt out of
          sharing your Personal Information with third parties for the third parties’ direct marketing purposes. Please
          contact us at <a class="link" href="mailto:privacy@electrifyamerica.com">privacy@electrifyamerica.com.</a> if
          you would like to do so.
        </p>
      </li>
      <li id="section12">
        <strong class="underline">Supplemental Information for Residents of California and Other U.S. States</strong>
        <ul class="lower-latin margin-top">
          <li>
            <strong class="underline">Data Subject Rights</strong>
            <p>
              If you live in California, Colorado, or certain other states that have adopted generally applicable
              privacy laws, you may have certain rights regarding Personal Information:
            </p>
            <ul>
              <li>
                <strong>Right to Know. </strong>You may have the right to request information about the categories of
                Personal Information we have collected about you, the categories of sources from which we collected the
                Personal Information, the purposes for collecting, selling, or sharing the Personal Information, and to
                whom we have disclosed your Personal Information and why. You may also have the right to request the
                specific pieces of Personal Information we have collected about you.
              </li>
              <li>
                <strong>Right to Delete. </strong>You may have the right to request that we delete Personal Information
                that we have collected from you.
              </li>
              <li>
                <strong>Right to Correct. </strong>You may have the right to request that we correct inaccurate Personal
                Information that we maintain about you.
              </li>
              <li>
                <strong>Right to Opt Out of Sales/Sharing/Targeted Advertising. </strong>You may have the right to opt
                out of (i) the sale or sharing of your Personal Information and (ii) targeted advertising.
              </li>
            </ul>
            <p>
              You may exercise any of the rights available to you via our webform
              <a class="link" rel="noopener" target="_blank" href="https://privacy.electrifyamerica.com/welcome"
                >https://privacy.electrifyamerica.com/welcome</a
              >, or by calling us at 1-833-632-2778.
            </p>
            <p>
              In order to fully exercise the Right to Opt Out of Sales/Sharing/Targeted Advertising you must undertake
              both of the following steps:
            </p>
            <ol>
              <li>
                Submit an Opt Out of Sales/Sharing/Targeted Advertising request through on our webform
                <a class="link" rel="noopener" target="_blank" href="https://privacy.electrifyamerica.com/welcome"
                  >https://privacy.electrifyamerica.com/welcome</a
                >.
              </li>
              <li>
                Disable the use of advertising cookies and other tracking technologies in the preference center,
                accessible via the “Do Not Sell” link at
                <a class="link" rel="noopener" target="_blank" href="https://www.electrifyamerica.com"
                  >https://www.electrifyamerica.com</a
                >. You must complete this step on each of our websites from each browser and on each device that you
                use. These steps are necessary so that we can place a first-party cookie signaling that you have opted
                out on each browser and each device you use. Please note:
                <ul class="lower-latin">
                  <li class="margin-top">
                    If you block cookies, we will be unable to comply with your request to opt out of
                    sales/sharing/targeting with respect to device data that we automatically collect and disclose to
                    third parties online using cookies, pixels, and other tracking technologies.
                  </li>
                  <li>
                    If you clear cookies, you will need to disable the use of all advertising cookies and tracking
                    technologies in the preference center again on each browser on each device where you have cleared
                    cookies.
                  </li>
                </ul>
              </li>
            </ol>
            <p>
              To the extent required by law, we will honor opt-out preference signals sent in a format commonly used and
              recognized by businesses, such as an HTTP header field or JavaScript object. We will process opt-out
              preference signals at the browser level.
            </p>
            <p>We will not discriminate against you for exercising your privacy rights.</p>
            <p>
              <strong><em>Verification:</em></strong> In order to process requests, we may need to obtain information to
              locate you in our records or verify your identity depending on the nature of the request. In most cases we
              will collect some or all of the following data elements: first and last name, email address. In some
              cases, we may request different or additional information, including a signed declaration that you are who
              you say you are, and will inform you if we need such information.
            </p>
            <p>
              <strong><em>Authorized Agents:</em></strong> Authorized agents may exercise rights on behalf of you by
              submitting a request via the webform at
              <a class="link" rel="noopener" target="_blank" href="https://privacy.electrifyamerica.com/welcome"
                >https://privacy.electrifyamerica.com/welcome</a
              >, and indicating that they are submitting the request as an agent. We may require the agent to
              demonstrate authority to act on behalf of you by providing signed permission from you. We may also require
              you to verify your own identity directly with us or to directly confirm with us that you provided the
              authorized agent permission to submit the request.
            </p>
            <p>
              <strong><em>Appeal:</em></strong> If we deny your rights request, you may have the right to appeal. To
              submit an appeal, contact us at
              <a class="link" href="mailto:privacy@electrifyamerica.com">privacy@electrifyamerica.com</a>. We will
              inform you in writing our response to your appeal.
            </p>
          </li>
          <li>
            <strong class="underline">Additional Data Processing Disclosures</strong>
            <p>
              In addition to the disclosures above, this section provides supplemental information about how we process
              Personal Information. These additional disclosures apply only to individuals who reside in California as
              required by the California Consumer Privacy Act ("CCPA").
            </p>
          </li>
        </ul>
        <strong class="underline">Disclosure of Personal Data</strong>
        <p>
          Although we have not "sold" Personal Information for money or monetary consideration in the past 12 months, we
          engage in routine practices involving third parties that could be considered a "sale" or “sharing” as defined
          under California law. We do not knowingly sell or share any Personal Information of minors under the age of
          16.
        </p>
        <p>
          Below please find a chart detailing the categories of Personal Information we collected and with whom it was
          sold, shared, or disclosed for a business purpose in the past 12 months.
        </p>
      </li>
    </ol>
    <table>
      <tr>
        <th>Categories of Personal Information We Collect</th>
        <th>Categories of Third Parties With Whom We Disclose Personal Information for a Business Purpose</th>
        <th>Categories of Third Parties to Whom Personal Data is Sold or Shared</th>
      </tr>
      <tr>
        <td>
          <strong><em>Identifiers</em></strong
          >, such as your name, alias, postal address, unique personal identifier, online identifier, charging station
          user ID, session ID, internet protocol address, email address, account name, telephone number, social media
          identifiers (e.g., Twitter handle, Instagram name, etc.), driver’s license number, credit card number, or
          other similar identifiers.
        </td>
        <td>
          <ul>
            <li>Affiliates and subsidiaries</li>
            <li>Service providers</li>
            <li>
              Third parties necessary to complete a transaction or request or with whom you have directed us to share
              your information
            </li>
            <li>Business partners</li>
            <li>For legal, security, and safety purposes</li>
            <li>In connection with a corporate transaction</li>
            <li>Entities to which you have consented to the disclosure</li>
          </ul>
        </td>
        <td>
          <ul>
            <li>Business partners</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <strong><em>Personal information subject to the California Customer Records Act,</em></strong> such as
          signatures.
        </td>
        <td>
          <ul>
            <li>Affiliates and subsidiaries</li>
            <li>Service providers</li>
            <li>
              Third parties necessary to complete a transaction or request or with whom you have directed us to share
              your information
            </li>
            <li>Business partners</li>
            <li>For legal, security, and safety purposes</li>
            <li>In connection with a corporate transaction</li>
            <li>Entities to which you have consented to the disclosure</li>
          </ul>
        </td>
        <td>
          <ul>
            <li>Business partners</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <strong><em>Characteristics of protected classifications under California or Federal Law,</em></strong> such
          as age and gender.
        </td>
        <td>
          <ul>
            <li>Affiliates and subsidiaries</li>
            <li>Service providers</li>
            <li>
              Third parties necessary to complete a transaction or request or with whom you have directed us to share
              your information
            </li>
            <li>Business partners</li>
            <li>For legal, security, and safety purposes</li>
            <li>In connection with a corporate transaction</li>
            <li>Entities to which you have consented to the disclosure</li>
          </ul>
        </td>
        <td>
          <ul>
            <li>Not sold or shared</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <strong><em>Commercial information,</em></strong> including records of personal property, product or services
          purchased, obtained or considered, or other purchasing or consuming histories or tendencies; the content,
          timing and method of communications you have had with us; payment and delivery or other financial information;
          and legal information relating to your transactions.
        </td>
        <td>
          <ul>
            <li>Affiliates and subsidiaries</li>
            <li>Service providers</li>
            <li>
              Third parties necessary to complete a transaction or request or with whom you have directed us to share
              your information
            </li>
            <li>Business partners</li>
            <li>For legal, security, and safety purposes</li>
            <li>In connection with a corporate transaction</li>
            <li>Entities to which you have consented to the disclosure</li>
          </ul>
        </td>
        <td>
          <ul>
            <li>Business partners</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <strong><em>Internet or other electronic network activity information,</em></strong> including, but not
          limited to, browsing history, search history, and information regarding a consumer’s interaction with an
          internet website, application or advertisement.
        </td>
        <td>
          <ul>
            <li>Affiliates and subsidiaries</li>
            <li>Service providers</li>
            <li>
              Third parties necessary to complete a transaction or request or with whom you have directed us to share
              your information
            </li>
            <li>Business partners</li>
            <li>For legal, security, and safety purposes</li>
            <li>In connection with a corporate transaction</li>
            <li>Entities to which you have consented to the disclosure</li>
          </ul>
        </td>
        <td>
          <ul>
            <li>Business partners</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <strong><em>Geolocation information,</em></strong> such as IP address and addresses of charging stations
          you’ve used to charge your vehicle.
        </td>
        <td>
          <ul>
            <li>Affiliates and subsidiaries</li>
            <li>Service providers</li>
            <li>
              Third parties necessary to complete a transaction or request or with whom you have directed us to share
              your information
            </li>
            <li>For legal, security, and safety purposes</li>
            <li>In connection with a corporate transaction</li>
            <li>Entities to which you have consented to the disclosure</li>
          </ul>
        </td>
        <td>
          <ul>
            <li>Marketing and research partners</li>
            <li>Vehicle manufacturers and dealers</li>
            <li>Commercial and/or fleet customers</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <strong><em>Audio, electronic, visual, thermal, olfactory, or similar information,</em></strong> such as CCTV
          footage, call recordings, web activity recordings, etc.
        </td>
        <td>
          <ul>
            <li>Affiliates and subsidiaries</li>
            <li>Service providers</li>
            <li>
              Third parties necessary to complete a transaction or request or with whom you have directed us to share
              your information
            </li>
            <li>Business partners</li>
            <li>For legal, security, and safety purposes</li>
            <li>In connection with a corporate transaction</li>
            <li>Entities to which you have consented to the disclosure</li>
          </ul>
        </td>
        <td>
          <ul>
            <li>Not sold or shared</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <strong><em>Professional, employment, or education information,</em></strong> such as job title, employer,
          business address and contact information, employment history, other professional information, or education
          history.
        </td>
        <td>
          <ul>
            <li>Affiliates and subsidiaries</li>
            <li>Service providers</li>
            <li>
              Third parties necessary to complete a transaction or request or with whom you have directed us to share
              your information
            </li>
            <li>Business partners</li>
            <li>For legal, security, and safety purposes</li>
            <li>In connection with a corporate transaction</li>
            <li>Entities to which you have consented to the disclosure</li>
          </ul>
        </td>
        <td>
          <ul>
            <li>Not sold or shared</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <strong><em>Education information.</em></strong>
        </td>
        <td>
          <ul>
            <li>Affiliates and subsidiaries</li>
            <li>Service providers</li>
            <li>
              Third parties necessary to complete a transaction or request or with whom you have directed us to share
              your information
            </li>
            <li>Business partners</li>
            <li>For legal, security, and safety purposes</li>
            <li>In connection with a corporate transaction</li>
            <li>Entities to which you have consented to the disclosure</li>
          </ul>
        </td>
        <td>
          <ul>
            <li>Not sold or shared</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <strong><em>Inferences</em></strong> drawn from any of the information we collect to assess the level of
          interest in our products and services based on frequency of visits and contact and determine your preferred
          frequency for receiving offers.
        </td>
        <td>
          <ul>
            <li>Affiliates and subsidiaries</li>
            <li>Service providers</li>
            <li>
              Third parties necessary to complete a transaction or request or with whom you have directed us to share
              your information
            </li>
            <li>Business partners</li>
            <li>For legal, security, and safety purposes</li>
            <li>In connection with a corporate transaction</li>
            <li>Entities to which you have consented to the disclosure</li>
          </ul>
        </td>
        <td>
          <ul>
            <li>Marketing and advertising partners</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <strong><em>Sensitive Personal Information,</em></strong> including account log-in information, precise
          geolocation and the content of mail, email, and text messages where we are not the intended recipient.
        </td>
        <td>
          <ul>
            <li>Affiliates and subsidiaries</li>
            <li>Service providers</li>
            <li>
              Third parties necessary to complete a transaction or request or with whom you have directed us to share
              your information
            </li>
            <li>Business partners</li>
            <li>For legal, security, and safety purposes</li>
            <li>In connection with a corporate transaction</li>
            <li>Entities to which you have consented to the disclosure</li>
          </ul>
        </td>
        <td>
          <ul>
            <li>Not sold or shared</li>
          </ul>
        </td>
      </tr>
    </table>
    <p>
      <strong>Sensitive Personal Information.</strong> We only use Sensitive Personal Information that is subject to the
      CCPA for the following purposes: (i) performing services or providing goods reasonably expected by an average
      consumer; (ii) detecting security incidents; (iii) resisting malicious, deceptive, or illegal actions; (iv)
      ensuring the physical safety of individuals; (v) for short-term, transient use, including non-personalized
      advertising; (vi) performing or providing internal business services; (vii) verifying or maintaining the quality
      or safety of a service or device; or (viii) for purposes that do not infer characteristics about you.
    </p>
  </article>
</template>

<script>
export default {
  name: 'privacy',
  metaInfo: {
    title: 'Privacy policy | Electrify America',
    meta: [
      {
        name: 'description',
        content:
          'Be informed. Read our privacy policy to learn how Electrify America will collect, access, use, and disclose your information when you use our products and services.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/privacy/' }],
  },
};
</script>
